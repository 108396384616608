<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card
            class="mx-auto"
        >
          <v-card-title>Settings</v-card-title>
          <v-divider></v-divider>
          <v-toolbar class="justify-end">
            <v-spacer/>
            <v-btn @click="clearCache" color="primary">Purge cache</v-btn>
          </v-toolbar>
          <v-card-text>
            <form @submit.prevent="submit">
              <v-row>
                <v-col cols="12" class="title">Site Information</v-col>
                <v-col cols="12" md="6">
                  <v-switch
                      v-model="maintenance_mode"
                      label="Maintenance mode"
                      @click="toggleMaintenanceMode"
                  />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-textarea
                      label="Maintenance message"
                      v-model="settings.maintenance_message"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Premium features (separate with comma)"
                              v-model="settings.premium"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Notice"
                      v-model="settings.notice"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input
                      truncate-length="40"
                      v-model="favicon"
                      label="Favicon"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img v-if="settings && settings.favicon"
                         max-height="80"
                         max-width="80"
                         :src="settings.favicon"
                  ></v-img>
                </v-col>


                <v-col cols="12" class="title">Payment Information</v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      label="bKash"
                      v-model="settings.bkash"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      label="Rocket"
                      v-model="settings.rocket"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      label="Nagad"
                      v-model="settings.nagad"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                      depressed
                      color="primary"
                      @click="submit"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <v-divider></v-divider>
          <payment-instructions></payment-instructions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import PaymentInstructions from "./utilities/PaymentInstructions";

export default {
  components: {PaymentInstructions},
  data() {
    return {
      settings: {
        bkash: '',
        rocket: '',
        nagad: '',
        maintenance_message: '',
        premium: '',
        notice: '',
      },
      favicon: null,
      logo: null,
      banner: null,
      maintenance_mode: false,
      premium: '',
    }
  },
  computed: {
    form() {
      let formData = new FormData();
      formData.append('bkash', this.settings.bkash ? this.settings.bkash : '')
      formData.append('rocket', this.settings.rocket ? this.settings.rocket : '')
      formData.append('nagad', this.settings.nagad ? this.settings.nagad : '')
      if (this.favicon) {
        formData.append('favicon', this.favicon)
      }
      formData.append('maintenance_message', this.settings.maintenance_message ? this.settings.maintenance_message : '')
      formData.append('notice', this.settings.notice ? this.settings.notice : '')
      formData.append('premium', this.settings.premium ? this.settings.premium : '')
      return formData;
    }
  },
  methods: {
    initialize() {
      const url = 'admin/settings'
      axios.get(url).then((response) => {
        this.settings = response.data ? response.data : {}
        this.maintenance_mode = response.data.maintenance_mode
        this.settings.premium = response.data.premium.toString()
      })
    },
    submit() {
      const url = 'admin/settings'
      axios.post(url, this.form).then((response) => {
        this.settings = response.data
        Swal.fire('Settings updated successfully')
        this.initialize()
        this.reset()
      }).catch(() => {
        Swal.fire('Error', 'Something went wrong!', 'warning')
      })
    },
    toggleMaintenanceMode() {
      const url = 'admin/settings'
      const data = {
        maintenance_mode: this.maintenance_mode
      }
      axios.post(url, data).then(response => {
        this.maintenance_mode = response.data.maintenance_mode
        if (response.data.maintenance_mode) {
          Swal.fire('Maintenance mode is on')
        } else {
          Swal.fire('Maintenance mode is off')
        }
      })
    },
    clearCache() {
      const url = 'admin/clear-cache'
      axios.get(url).then(response => {
        Swal.fire(response.data)
      })
    },
    reset() {
      this.favicon = null;
    },
  },
  created() {
    this.initialize()
  },
}
</script>
