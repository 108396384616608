<template>
  <v-form @submit.prevent="submit">
    <v-row class="pa-4">
      <v-col>
        <div class="title">Payment Instructions</div>
      </v-col>
      <v-col
          cols="12"
      >
        <!--        <quill-editor-->
        <!--            :options="{placeholder: 'Enter course payment instructions'}"-->
        <!--            v-model="course_payment"-->
        <!--        ></quill-editor>-->
        <v-text-field label="Enter course payment instructions" v-model="course_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="course_payment"></div>
      </v-col>
      <v-col
          cols="12"
      >
        <!--        <quill-editor-->
        <!--            :options="{placeholder: 'Enter book order payment instruction'}"-->
        <!--            v-model="book_payment"-->
        <!--        ></quill-editor>-->
        <v-text-field label="Enter book order payment instruction" v-model="book_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="book_payment"></div>
      </v-col>
      <v-col
          cols="12"
      >
        <!--        <quill-editor-->
        <!--            :options="{placeholder: 'Enter free access instruction'}"-->
        <!--            v-model="manual_payment"-->
        <!--        ></quill-editor>-->
        <v-text-field label="Enter free access instruction" v-model="manual_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="manual_payment"></div>
      </v-col>
      <v-col
          cols="12"
      >
        <!--        <quill-editor-->
        <!--            :options="{placeholder: 'Enter sms alert payment instruction'}"-->
        <!--            v-model="sms_payment"-->
        <!--        ></quill-editor>-->
        <v-text-field label="Enter sms alert payment instruction" v-model="sms_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="sms_payment"></div>
      </v-col>
      <v-col
          cols="12"
      >
        <!--        <quill-editor-->
        <!--            :options="{placeholder: 'Enter form fill up payment instruction'}"-->
        <!--            v-model="form_fill_up_payment"-->
        <!--        ></quill-editor>-->
        <v-text-field label="Enter form fill up payment instruction" v-model="form_fill_up_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="form_fill_up_payment"></div>
      </v-col>
      <v-col
          cols="12"
      >
        <v-text-field label="Enter premium membership payment instruction" v-model="premium_membership_payment"></v-text-field>
        <v-divider></v-divider>
        <div v-html="premium_membership_payment"></div>
      </v-col>
      <v-col>
        <v-btn type="submit" color="primary">Save</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import {quillEditor} from 'vue-quill-editor'

export default {
  components: {
    // quillEditor
  },
  data() {
    return {
      course_payment: '',
      book_payment: '',
      manual_payment: '',
      sms_payment: '',
      form_fill_up_payment: '',
      premium_membership_payment: '',
    }
  },
  computed: {
    form() {
      let formData = new FormData()
      formData.append('course_payment', this.course_payment)
      formData.append('book_payment', this.book_payment)
      formData.append('manual_payment', this.manual_payment)
      formData.append('sms_payment', this.sms_payment)
      formData.append('form_fill_up_payment', this.form_fill_up_payment)
      formData.append('premium_membership_payment', this.premium_membership_payment)
      return formData
    }
  },
  methods: {
    initialize() {
      const url = 'admin/instructions'
      axios.get(url).then((response) => {
        if (response.data) {
          this.book_payment = response.data.book_payment
          this.course_payment = response.data.course_payment
          this.manual_payment = response.data.manual_payment
          this.sms_payment = response.data.sms_payment
          this.form_fill_up_payment = response.data.form_fill_up_payment
          this.premium_membership_payment = response.data.premium_membership_payment
        }
      })
    },
    submit() {
      const url = 'admin/instructions'
      axios.post(url, this.form).then(() => {
        Swal.fire('Settings updated successfully')
      }).catch(() => {

      })
    }
  },
  mounted() {
    this.initialize()
  }
}
</script>